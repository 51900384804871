<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title" :visible.sync="isVisible" :before-close="handleClose" :detailId="detailId" :append-to-body="true">
    <el-form ref="form" :model="articleData" :rules="rules" label-width="80px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="标题" prop="title">
            <el-input v-model="articleData.title" placeholder="标题"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上架" prop="is_on">
            <el-radio v-model="articleData.is_on" label="1">是</el-radio>
            <el-radio v-model="articleData.is_on" label="0">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="封面图" prop="img_url">
            <el-upload
              ref="upload"
              :action="uploadImage.action"
              :headers="uploadImage.headers"
              :file-list="uploadImage.fileList"
              :on-success="handleUpload"
              list-type="picture-card"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="分类" prop="category_id">
            <el-cascader
              v-model="pidValue"
              :options="pidOptions"
              :props="{ checkStrictly: true }"
              @change="handleChange">
            </el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="浏览次数" prop="browse_num">
            <el-input-number v-model="articleData.browse_num" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序" prop="sort_num">
            <el-input-number v-model="articleData.sort_num" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="简介" prop="profile">
            <el-input v-model="articleData.profile" placeholder="简介"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="seo标题" prop="seo_title">
            <el-input v-model="articleData.seo_title"  placeholder="seo标题"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="seo关键字" prop="seo_keywords">
            <el-input v-model="articleData.seo_keywords"  placeholder="关键字"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="seo描述" prop="seo_description">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="seo描述"
              v-model="articleData.seo_description">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="height: 300px;">
        <el-col :span="24">
          <el-form-item label="内容" prop="content">
          <quill-editor @EditorContent="handleContent" :Content="articleData.content"></quill-editor>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import quillEditor from "@/components/Editor";
import handleTreeData from "@/utils/utils";
import tool from "@/utils/utils";
export default {
  name: "ArticleAddUpdate",
  data(){
    return {
      articleData: {
        id:1,
        category_id:0,
        level:"",
        title:"",
        img_url:"",
        profile:"",
        sort_num:1,
        is_on:"0",
        browse_num:0,
        content:"",
        seo_title:"",
        seo_description:"",
        seo_keywords:"",
      },
      uploadImage:{
        action:this.$http.defaults.baseURL+"/upload_file/index",
        dialogImageUrl: '',
        dialogVisible: false,
        headers:{
          Authorization:sessionStorage.getItem("token")
        },
        fileList:[],
      },
      rules:{
        title:[
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        image_url:[
          { required: true, message: '请上传图片', trigger: 'blur' },
        ],
        sort_num:[
          { required: true, message: '请输入排序号', trigger: 'blur' },
        ],
        profile:[
          { required: true, message: '请输入简介', trigger: 'blur' },
        ],
        seo_title:[
          { required: true, message: '请输入seo标题', trigger: 'blur' },
        ],
        seo_description:[
          { required: true, message: '请输入seo描述', trigger: 'blur' },
        ],
        seo_keywords:[
          { required: true, message: '请输入seo关键字', trigger: 'blur' },
        ],
        content:[
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
      },
      //分页数据
      pageInfo:{
        //当前页
        currentPage:1,
        //每页显示条数
        pageSize:-1,
      },
      pidValue:[],
      pidOptions:[
        {
          value: 0,
          label: "顶级",
          children:[],
        }
      ],
    }
  },
  //定义接受父组件传来的数据
  props:{
    isVisible:Boolean,
    title:String,
    detailId:Number,
  },
  methods:{
    query(){
      this.$http({
        method: 'post',
        url: '/category/custom_list',
        data: {
          page:this.pageInfo.currentPage,
          size:this.pageInfo.pageSize,
        },
      }).then(res=>{
        if (res.data.code==0) {
          let handleData = tool.getTreeData(res.data.data.list)
          this.pidOptions[0].children = handleData
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    //关闭之前的处理方法,向父组件传一个ChangVisible事件，false值,从而隐藏弹框
    handleClose(){
      this.$emit("ChangeVisible",false)
    },
    //根据id来判断是添加还是更新
    handleSubmit(){
      if (this.detailId!=0){
        this.$refs.form.validate((val)=>{
          if (!val){
            this.$message.error("请输入必填项")
            return false
          }
          let postData = this.articleData
          postData.is_on = Number(postData.is_on)
          this.$http({
            method: 'post',
            url: '/article/update',
            data: postData,
          }).then(res=>{
            if (res.data.code==0) {
              //清空表单
              this.$refs.form.resetFields()
              //关闭弹窗
              this.handleClose()
              //消息提示
              this.$message.success("操作成功")
              //子传父，调用父组件的query方法，重新加载数据
              this.$emit("query")
            }else{
              this.$message.error(res.data.msg)
            }
          }).catch(err=>{
            console.log(err)
          })
        })
      }else{
        this.$refs.form.validate((val)=>{
          if (!val){
            this.$message.error("请输入必填项")
            return false
          }
          let postData = this.articleData
          postData.is_on = Number(postData.is_on)
          this.$http({
            method: 'post',
            url: '/article/add',
            data: postData,
          }).then(res=>{
            if (res.data.code==0) {
              //清空表单
              this.$refs.form.resetFields()
              //关闭弹窗
              this.handleClose()
              //消息提示
              this.$message.success("操作成功")
              //子传父，调用父组件的query方法，重新加载数据
              this.$emit("query")
            }else{
              this.$message.error(res.data.msg)
            }
          }).catch(err=>{
            console.log(err)
          })

        })
      }
    },
    //分类选择
    handleChange(val){
      console.log(val,val[val.length-1])
      this.articleData.category_id = val[val.length-1]
      this.articleData.level = JSON.stringify(val)
    },
    handleContent(value){
      this.articleData.content = value
    },
    handleUpload(val){
      if (val.code==0){
        this.articleData.img_url = val.data.url
      }
    },
    //清空表单数据
    handleFormData(){
      console.log("handleFormData")
      this.$refs.form.resetFields()
      this.pidValue = []
      this.uploadImage.fileList = []
      this.query()
    },
  },

  mounted() {
    this.query()
  },
  components:{
    quillEditor
  },
  watch:{
    detailId(val){
      if (val==0){
        return
      }
      this.$http({
        method: 'post',
        url: '/article/detail',
        data: {
          id: val
        },
      }).then(res=>{
        if (res.data.code==0) {
          /* let regObj = new RegExp(",")
           let pidValue = res.data.data.level.split(regObj).map(item=>Number(item))
          if (res.data.data.pid!=0){
            pidValue.push(res.data.data.id)
          }
          this.pidValue = pidValue*/
          //处理level
          let level = JSON.parse(res.data.data.level)
          this.pidValue = level
          console.log("pidValue",this.pidValue)
          this.uploadImage.fileList = []
          for (let x in res.data.data) {
            if (x=="update_time"||x=="create_time"){
              continue
            }
            if (x=="is_on"){
              this.articleData[x] = res.data.data[x].toString()
              continue
            }
            if (x=="img_url"){
               let baseUrl =  this.$siteUrl
               let imgUrl = baseUrl+res.data.data[x].substring(1)
               console.log("imgUrl",imgUrl)
               this.uploadImage.fileList.push({url:imgUrl})
            }
            this.articleData[x] = res.data.data[x]
          }
        }else {
          this.$message.error(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
